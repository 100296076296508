import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-simple-field',
  templateUrl: './simple-field.component.html',
  styleUrls: ['./simple-field.component.css']
})
export class SimpleFieldComponent {
  @Input() label: String = '';
  @Input() value: String = '';
  @Output() valueChange = new EventEmitter<String>();

  hide = true;

  toggleVisibility() {
    this.hide = !this.hide;
  }

  onValueInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    this.valueChange.emit(this.value);
  }

  saveToClipboard() {
    navigator.clipboard.writeText(this.value.toString());
  }
}
