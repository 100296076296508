import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapsable-wrapper',
  templateUrl: './collapsable-wrapper.component.html',
  styleUrls: ['./collapsable-wrapper.component.css']
})
export class CollapsableWrapperComponent {
  @Input() title: String = '';
  @Input() description: String = '';
}
