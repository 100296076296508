<mat-toolbar>
  <span>PASH</span>
</mat-toolbar>

<div class="content">
  <div *ngIf="!state.vault">
    <form>
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput (change)="updateUserName($event)" type="text" value=""/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput (change)="updateMasterPassword($event)" type="password" value=""/>
      </mat-form-field>
    </form>

    <button mat-flat-button color="primary" (click)="loadVault()">Login</button>
  </div>
  <br><br>
  <button mat-flat-button color="warn" (click)="logout()">Logout</button>

  <br><br>
  <button mat-flat-button color="accent" *ngIf="state.vault && state.mode == 'VIEW'" (click)="saveVault()">Save Vault</button>
  <br><br>
  <button mat-flat-button color="accent" *ngIf="state.vault && state.mode == 'VIEW'" (click)="goToAddEntry()">Add an Entry</button>
  <br><br>

  <form *ngIf="state.vault && state.mode == 'VIEW'">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (change)="updateFilter($event)" type="text" value=""/>
    </mat-form-field>
  </form>

  <mat-accordion class="example-headers-align" multi>
  <div *ngIf="state.vault && state.mode == 'VIEW'">
    <div *ngFor="let entry of filteredEntries(); let index = index">

      <div *ngIf="entry.Website">
        <app-collapsable-wrapper [title]="entry.Website.name || '??'" [description]="entry.Website.username || ''">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{entry.Website.name || 'untitled'}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
              <app-simple-field label="'Name'" [(value)]="entry.Website.name!"></app-simple-field>
              <app-simple-field label="'Username'" [(value)]="entry.Website.username!"></app-simple-field>
              <app-password-field [(password)]="entry.Website.password!"></app-password-field>
              <app-simple-field label="'Email'" [(value)]="entry.Website.email!"></app-simple-field>
              <app-simple-field label="'URL'" [(value)]="entry.Website.uri!"></app-simple-field>
          </mat-card-content>
        </mat-card>
        <mat-card-footer>
          <button mat-flat-button color="warn" (click)="deleteEntry(index)">Delete Entry</button>
        </mat-card-footer>
        </app-collapsable-wrapper>
      </div>

    </div>
  </div>
  </mat-accordion>

  <div *ngIf="state.mode == 'ADD'">
    <div *ngIf="state.addType == 'Website'">
      <app-simple-field id="add-name" label="'Name'"></app-simple-field>
      <app-simple-field id="add-username" label="'Username'"></app-simple-field>
      <app-password-field id="add-password"></app-password-field>
      <app-simple-field id="add-email" label="'Email'"></app-simple-field>
      <app-simple-field id="add-uri" label="'URL'"></app-simple-field>
      <app-simple-field id="add-notes" label="'Notes'"></app-simple-field>
    </div>

    <button mat-flat-button color="primary" (click)="saveEntry()">Save</button>
    <button mat-flat-button color="accent" (click)="discardEntry()">Discard</button>
  </div>
</div>
