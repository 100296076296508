import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.css']
})
export class PasswordFieldComponent {
  @Input() password: String = '';
  @Output() passwordChange = new EventEmitter<String>();

  hide = true;

  toggleVisibility() {
    this.hide = !this.hide;
  }

  onPasswordInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.password = inputElement.value;
    this.passwordChange.emit(this.password);
  }

  saveToClipboard() {
    navigator.clipboard.writeText(this.password.toString());
  }
}
