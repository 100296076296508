<div class="password-field">
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" [value]="password || ''" (input)="onPasswordInput($event)">
      <button mat-icon-button matSuffix (click)="toggleVisibility()">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
      <button mat-icon-button matSuffix (click)="saveToClipboard()">
        <mat-icon>{{'Content Copy'}}</mat-icon>
      </button>
    </mat-form-field>
  </div>
