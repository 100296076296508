import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';

export class Website {
  name?:     String;
  username?: String;
  password?: String;
  uri?:      String;
  email?:    String;
  notes?:    String;
}

export class Entry {
  Website?: Website;
}

export class Vault {
  entries: Entry[]

  constructor(entries: [Entry]) {
      this.entries = entries;
  }
}

export class GetVaultRequest {
  username: String;
  password: String;

  constructor(username: String, password: String) {
      this.username = username;
      this.password = password;
  }
}

export class PutVaultRequest {
  username: String;
  password: String;
  vault: Vault

  constructor(username: String, password: String, vault: Vault) {
      this.username = username;
      this.password = password;
      this.vault = vault;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VaultService {
  configUrl = "https://pashapi.org/api/vault";

  constructor(private http: HttpClient) {
  }

  getVault(username: String, password: String) {
    // let vault: Vault = {
    //   entries: [
    //     {
    //       Website: {
    //         name: 'name one',
    //         username: 'vicvele',
    //         password: 'password'
    //       }
    //     },
    //     {
    //       Website: {
    //         name: 'name two',
    //         username: 'vicvele',
    //         password: 'password'
    //       }
    //     }
    //   ]
    // };
    // return of(vault);

    return this.http.post<Vault>(this.configUrl, { username: username, password: password });
  }

  saveVault(username: String, password: String, vault: Vault) {
    // return of([]);

    return this.http.put<Vault>(this.configUrl, {
      username: username,
      password: password,
      vault: vault
    });
  }
}
