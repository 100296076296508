<div class="simple-field">
    <mat-form-field appearance="outline">
      <mat-label>{{ label }}</mat-label>
      <input matInput [value]="value || ''" (input)="onValueInput($event)">
      <button mat-icon-button matSuffix (click)="saveToClipboard()">
        <mat-icon>{{'Content Copy'}}</mat-icon>
      </button>
    </mat-form-field>
  </div>

