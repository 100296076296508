import { Injectable } from '@angular/core';
import { Vault } from './vault.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  mode = '';
  addType = '';

  vault?: Vault;

  masterPassword = '';
  userName = '';

  filter: string | null = null;

  constructor() { this.reset(); }

  reset() {
    this.vault = undefined;
    this.masterPassword = "";
    this.userName = "";

    this.mode = 'VIEW';
    this.addType = 'Website';
    this.filter = null;
  }
}
