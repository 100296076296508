import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { StateService } from './state.service';
import { VaultService } from './vault.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  types = [
    "Website"
  ];

  constructor(
    private vaultService: VaultService,
    public state: StateService,
    private snackBar: MatSnackBar) { }

  loadVault() {
    this.vaultService.getVault(
      this.state.userName,
      this.state.masterPassword
    )
      .subscribe({
        next:  vault  => this.state.vault = vault,
        error: error  => this.snackBar.open("Failed to laod vault", undefined, { duration: 5000 })
      });
  }

  saveVault() {
    if (this.state.vault) {
      this.vaultService.saveVault(
        this.state.userName,
        this.state.masterPassword,
        this.state.vault
      )
        .subscribe({
          next:  _      => this.snackBar.open("Saved vault :)",          undefined, { duration: 500 }),
          error: _      => this.snackBar.open("Failed to save vault :(", undefined, { duration : 5000 })
        });
    }
  }

  update(entry: any, field: string, event: any) {
    entry[field] = event.target.value;
  }

  updateUserName(event: any) {
    this.state.userName = event.target.value;
  }

  updateMasterPassword(event: any) {
    this.state.masterPassword = event.target.value;
  }

  goToAddEntry() {
    this.state.mode = 'ADD';
  }

  updateAddType(event: any) {
    this.state.addType = event.target.value;
  }

  deleteEntry(index: number) {
    if (!this.state.vault) {
      return;
    }

    this.state.vault.entries.splice(index, 1);
    this.saveVault();
  }

  saveEntry() {
    if (!this.state.vault) {
      return;
    }

    // TODO get rid of this query selector hack by listening to
    // emitted events from the simple component
    console.log(document.getElementById('add-name'));

    this.state.vault?.entries.push({
      Website: {
        name:     (document.getElementById('add-name')     as any).value || undefined,
        username: (document.getElementById('add-username') as any).value || undefined,
        password: (document.getElementById('add-password') as any).value || undefined,
        email:    (document.getElementById('add-email')    as any).value || undefined,
        uri:      (document.getElementById('add-uri')      as any).value || undefined,
      }
    })

    this.saveVault();
    this.state.mode = 'VIEW';
  }

  discardEntry() {
    this.state.mode = 'VIEW';
  }

  updateFilter(event: any) {
    this.state.filter = event.target.value || null;
  }

  filteredEntries() {
    let entries = this.state.vault?.entries;

    if (this.state.filter && this.state.filter.length > 0) {
      let filter_lower = this.state.filter.toLowerCase();

      return entries?.filter(x => x.Website?.name?.toLowerCase().includes(filter_lower));
    }
    else {
      return entries;
    }
  }

  logout() {
    this.state.reset();
  }
}
